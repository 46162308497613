import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import {
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import "../styles/Signup.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastify } from "../component/Toast";
const Signup = () => {
  const backendURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{12}$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (isValidPhoneNumber(formData.phoneNumber)) {
      console.log("Valid phone number:", formData.phoneNumber);
    } else {
      setLoader(false);
      toastify("error", "Invalid Phone number");
      return;
    }

    try {
      const { firstname, lastname, email, password, phoneNumber } = formData;

      const response = await axios.post(`${backendURL}/user/create`, {
        firstname,
        lastname,
        email,
        password,
        phoneNumber,
      });

      if (response.status === 200) {
        toastify("success", "User registered successfully!");

        toastify("success", "OTP is sent to your email");
        setLoader(false);
        setTimeout(() => {
          navigate("/verify", { state: { userEmail: email } });
        }, 2000);
      } else if (response.status === 409) {
        console.error(`User with email ${email} already exists.`);
        toastify("error", "User with this email already exists.");
        setLoader(false);
      } else {
        console.error(`Error during signup: ${response.message}`);
        setLoader(false);
        toastify("error", response.message);
      }
    } catch (error) {
      console.log("eeee", error);
      console.error(
        "An error occurred during signup",
        error?.response?.data?.message
      );
      setLoader(false);
      toastify("error", error?.response?.data?.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="signup-container">
        <Paper className="signup-inner" elevation={3}>
          <Typography variant="h6" align="center">
            Sign Up
          </Typography>
          <form className="form-container" onSubmit={handleSubmit}>
            <TextField
              label="Firstname"
              name="firstname"
              variant="outlined"
              fullWidth
              value={formData.firstname}
              onChange={handleChange}
              required
            />
            <TextField
              label="Lastname"
              name="lastname"
              variant="outlined"
              fullWidth
              value={formData.lastname}
              onChange={handleChange}
              required
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              fullWidth
              value={formData.password}
              onChange={handleChange}
              required
            />{" "}
            <TextField
              label="Phone number"
              name="phoneNumber"
              type="number"
              placeholder="+92...."
              variant="outlined"
              fullWidth
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loader}
            >
              {loader ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Sign Up"
              )}
            </Button>
            <Typography textAlign={"center"}>
              Already a user? <a href="/">Login</a>
            </Typography>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default Signup;
