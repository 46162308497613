import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Avatar,
  InputLabel,
  FormControl,
} from "@mui/material";
import "../styles/Profile.css";

import { ToastContainer } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

const Profile = () => {
  const userInfo = localStorage.getItem("userData");
  const storedUser = JSON.parse(userInfo);
  const [user, setUser] = useState({});
  const backendURL = process.env.REACT_APP_API_URL;

  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editProfileState, setEditProfileState] = useState(true);

  const onEditprofile = () => {
    setEditProfileState(!editProfileState);
  };

  const onImageSelect = (e) => {
    setImage(e.target.files[0]);
    setShowImage(URL.createObjectURL(e.target.files[0]));
  };
  const editProfileHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onProfileSave = async () => {
    try {
      if (user.firstname || user.lastname || image) {
        const formData = new FormData();
        formData.append(
          "firstname",
          user.firstname ? user.firstname : storedUser?.firstname
        );
        formData.append(
          "lastname",
          user.lastname ? user.lastname : storedUser?.lastname
        );
        formData.append("file", image);

        await axios
          .put(`${backendURL}/user/edit-user/${storedUser?._id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
            setIsLoading(false);
            setEditProfileState(true);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <>
        <ToastContainer />
        <div className="user-profile p-5">
          <div className="profile-header"></div>
          <div className="profile-main-container">
            <Card>
              <div className="profile-btn">
                <Button variant="outlined" onClick={onEditprofile}>
                  {editProfileState ? "Edit Profile" : "Cancel"}
                </Button>
              </div>
              <div className="profile-card-container coach-profile py-3">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4} md={3}>
                    <div>
                      {showImage !== null ? (
                        <Avatar
                          src={showImage}
                          alt="Profile Picture"
                          sx={{ width: 200, height: 200, marginRight: "2rem" }}
                        />
                      ) : (
                        <Avatar
                          src={
                            storedUser?.imageUrl
                              ? storedUser?.imageUrl
                              : "/img/sample_img_2.png"
                          }
                          alt="Profile Picture"
                          sx={{ width: 200, height: 200, marginRight: "2rem" }}
                        />
                      )}
                      {!editProfileState && (
                        <>
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            id="file-input"
                            style={{ display: "none" }}
                            onChange={onImageSelect}
                          />
                          <label htmlFor="file-input">
                            <Button
                              variant="outlined"
                              className="edit-button-profile"
                              component="span"
                            >
                              <EditIcon fontSize="small" />
                            </Button>
                          </label>
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl
                          style={{
                            marginTop: "15px",
                          }}
                          fullWidth
                        >
                          <InputLabel
                            htmlFor="role"
                            shrink
                            style={{
                              background: "#fff",
                              paddingInline: "3.5px",
                            }}
                          >
                            First name
                          </InputLabel>
                          <TextField
                            disabled={editProfileState}
                            fullWidth
                            name="firstname"
                            defaultValue={storedUser?.firstname}
                            onChange={(e) => editProfileHandler(e)}
                          ></TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl
                          style={{
                            marginTop: "15px",
                          }}
                          fullWidth
                        >
                          <InputLabel
                            htmlFor="role"
                            shrink
                            style={{
                              background: "#fff",
                              paddingInline: "3.5px",
                            }}
                          >
                            Lastname
                          </InputLabel>
                          <TextField
                            disabled={editProfileState}
                            name="lastname"
                            defaultValue={storedUser?.lastname}
                            placeholder="Enter lastname"
                            onChange={(e) => editProfileHandler(e)}
                            className="mt-4 welcomeBoxTextField"
                            fullWidth
                          ></TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl
                          style={{
                            marginTop: "15px",
                          }}
                          fullWidth
                        >
                          <InputLabel
                            htmlFor="role"
                            shrink
                            style={{
                              background: "#fff",
                              paddingInline: "3.5px",
                            }}
                          >
                            Email
                          </InputLabel>
                          <TextField
                            disabled={true}
                            data-testid="dateSelectDOB"
                            defaultValue={storedUser?.email}
                            placeholder="Enter Email"
                            onChange={(e) => editProfileHandler(e)}
                            className="mt-4 welcomeBoxTextField"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <FormControl
                          style={{
                            marginTop: "15px",
                          }}
                          fullWidth
                        >
                          <InputLabel
                            htmlFor="role"
                            shrink
                            style={{
                              background: "#fff",

                              paddingInline: "3.5px",
                            }}
                          >
                            Phone number
                          </InputLabel>
                          <TextField
                            disabled={true}
                            data-testid="dateSelectDOB"
                            defaultValue={storedUser?.phoneNumber}
                            placeholder="Enter password"
                            onChange={(e) => editProfileHandler(e)}
                            className="mt-4 welcomeBoxTextField"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {!editProfileState && (
                  <div className="profile-btn-save">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onProfileSave}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} /> : "Update"}
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </>
      <>
        <ToastContainer />
      </>
    </>
  );
};

export default Profile;
