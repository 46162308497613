import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/Sidebar.css";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";

const Sidebar = () => {
  return (
    <div>
      <List className="sidebar-container">
        <ListItem component={NavLink} to="/dashboard">
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem component={NavLink} to="/products">
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem component={NavLink} to="/profile">
          <ListItemText primary="Profile" />
        </ListItem>
        <ListItem component={NavLink} to="/payment">
          <ListItemText primary="Payment" />
        </ListItem>
        <ListItem component={NavLink} to="/">
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;
