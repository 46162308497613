import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
} from "@mui/material";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cartItems = location?.state?.cartItems || [];
  const totalSum = location?.state?.totalPrice || [];
  const checkoutHandler = () => {
    navigate("/payment", { state: { amount: totalSum, cartItems: cartItems } });
  };
  return (
    <Grid>
      <Typography variant="h4" margin={"30px"}>
        Checkout
      </Typography>
      <Grid
        item
        display="flex"
        justifyContent="center"
        alignItems="center"
        direction={"column"}
      >
        {cartItems.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ width: "50%", marginTop: "50px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>

                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.price * item.quantity}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    <strong>Total:</strong> ${totalSum.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px", alignItems: "left" }}
          onClick={checkoutHandler}
        >
          Checkout
        </Button>
      </Grid>
    </Grid>
  );
};

export default Checkout;
