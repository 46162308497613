import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../component/PaymentForm";
import axios from "axios";
import { useLocation } from "react-router-dom";

const PUBLIC_KEY = process.env.REACT_APP_PUBLISHABLE_KEY;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Payment = (props) => {
  const location = useLocation();
  const [secretKey, setSecretKey] = useState("second");
  const cartItems = location?.state?.cartItems || [];

  const totalAmount = location?.state?.amount || [];
  const backendURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(
          `${backendURL}/transaction/create-payment-intent`,
          {
            amount: totalAmount,
          }
        );

        const { clientSecret, message } = response.data;

        setSecretKey(clientSecret);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchClientSecret();
  }, []); 

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <Elements stripe={stripeTestPromise}>
        <div className="mt-5 " style={{ width: "500px" }}>
          <PaymentForm cartItems={cartItems} clientSecret={secretKey} />
        </div>
      </Elements>
    </div>
  );
};

export default Payment;
