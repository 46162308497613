import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toastify } from "./Toast";
import { CircularProgress } from "@mui/material";

const myStyle = {
  backgroundColor: "#f2f2f4",
  padding: "10px 20px",
  border: "unset",
  borderRadius: "5px",
  marginTop: "30px",
  width: "20%",
  cursor: "pointer",
};

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(false);
  const backendURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const handlePaymentConfirmation = async (transactionData) => {
    try {
      const response = await axios.post(
        `${backendURL}/transaction/save-transaction-detail`,
        transactionData
      );
    } catch (error) {
      console.error("Error saving transaction details:", error.message);
    }
  };
  const handleSubmit = async (e) => {
    setLoader(true);

    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "Shaheryar",
        },
      },
    });

    if (result.error) {
      setLoader(false);
      console.log(result.error.message);
    } else {
      const transactionData = {
        userId: userId,
        cartItems: props.cartItems,
        amount: result?.paymentIntent?.amount,
        paymentMethodId: result?.paymentIntent?.payment_method,
        paymentId: result?.paymentIntent?.id,
        status: result?.paymentIntent?.status,
      };
      toastify("success", "Payment Successfully done");
      handlePaymentConfirmation(transactionData);
      setTimeout(() => {
        setLoader(false);
        navigate("/home");
      }, 2000);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  return (
    <div>
      <ToastContainer />

      <form onSubmit={handleSubmit}>
        Add card details
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement id="card-element" options={cardStyle} />
          </div>
        </fieldset>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <button style={myStyle} disabled={!stripe}>
            {loader ? <CircularProgress size={24} color="inherit" /> : "Pay"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
