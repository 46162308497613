// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.signup-inner {
  width: 500px;
  padding: 25px;
}
.signup-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Signup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".form-container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.signup-inner {\n  width: 500px;\n  padding: 25px;\n}\n.signup-container {\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
