import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Toast } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const Layout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-between">
        <Toast />
        {currentPath === "/" ||
        currentPath === "/signup" ||
        currentPath === "/verify" ||
        currentPath === "/forgetpassword" ? null : (
          <Navbar />
        )}

        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
