// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-main-container {
  position: relative;
  /* background: linear-gradient(#1b46b4, #3e4378); */
  background: #1b46b4;
}
.navbar-welcome-name {
  color: #ffffff;
}
.css-19r6kue-MuiContainer-root {
  max-width: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mDAAmD;EACnD,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".navbar-main-container {\n  position: relative;\n  /* background: linear-gradient(#1b46b4, #3e4378); */\n  background: #1b46b4;\n}\n.navbar-welcome-name {\n  color: #ffffff;\n}\n.css-19r6kue-MuiContainer-root {\n  max-width: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
