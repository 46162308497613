import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";
import { toastify } from "../component/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Login() {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");
  const backendURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if (userToken) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
    }
  }, []);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (email && password) {
      const apiUrl = `${backendURL}/user/login`;

      try {
        const response = await axios.post(apiUrl, {
          email: email,
          password: password,
          role: "user",
        });
        if (
          response?.data?.status === 200 &&
          response?.data?.data.role === "user"
        ) {
          const { data } = response.data;
          localStorage.setItem("token", data.token);
          localStorage.setItem("userId", data.userId);
          setLoader(false);

          navigate("/home");
        } 
      } catch (error) {
        setLoader(false);
        toastify("error", error.response.data.message);
      }
    } else {
      setLoader(false);
      toastify("error", "Enter email and password");
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <div className="login-container">
            <Typography variant="h6" align="center">
              LOGIN
            </Typography>
            <form className="form-container" onSubmit={handleLogin}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loader}
                  >
                    {loader ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <Typography textAlign={"center"} marginTop={"30px"}>
                    Already logged in? <a href="/signup">Signup</a>
                  </Typography>
                  <Typography textAlign={"center"} marginTop={"10px"}>
                    <a href="/forgetpassword">Forget Password ?</a>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
}

export default Login;
