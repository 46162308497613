import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toastify } from "../component/Toast";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const backendURL = process.env.REACT_APP_API_URL;

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const handlePasswordConfirmation = async () => {
    setLoader(true);
    if (password !== confirmPassword) {
      toastify("error", "Passwords do not match");
      setLoader(false);

      return;
    }
    try {
      const response = await axios.post(`${backendURL}/user/reset-password`, {
        token,
        password,
      });
      if (response.status === 200) {
        console.log("first", response.data.resetToken);
        toastify("success", response.data.message);
        navigate("/");
        setLoader(false);
      }
    } catch (error) {
      toastify("error", error.response.data.message);
      setLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <div className="login-container">
            <Typography variant="h4" align="center" marginBottom={"32px"}>
              New password
            </Typography>
            <Typography marginBottom={"15px"}>
              Enter your new password
            </Typography>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              <TextField
                fullWidth
                label="confirm password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
                autoFocus
              />
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePasswordConfirmation}
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Confirm"
                )}
              </Button>
            </Box>{" "}
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};
export default ResetPassword;
