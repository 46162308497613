import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "axios";
import { toastify } from "../component/Toast";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);  
  const backendURL = process.env.REACT_APP_API_URL;
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState(false);
  const location = useLocation();
  const email = location.state.userEmail;

  const handleVerifyOtp = async () => {
    setLoader(true);
    try {
      const response = await axios.post(`${backendURL}/user/verify-otp`, {
        email,
        otp,
      });

      toastify("success", response.data.message);
      setLoader(false);
      navigate("/home");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage("Error verifying OTP. Please try again.");
      setLoader(false);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <div className="login-container">
            <Typography variant="h4" align="center" marginBottom={"32px"}>
              Verify OTP
            </Typography>
            <div>
              <MuiOtpInput value={otp} onChange={(otp) => setOtp(otp)} />
            </div>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyOtp}
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verify"
                )}
              </Button>
              <div>{message}</div>
            </Box>{" "}
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};
export default VerifyOTP;
