import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toastify } from "../component/Toast";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const backendURL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailSender = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(`${backendURL}/user/forgetpassword`, {
        email,
      });
      if (response.status === 200) {
        toastify("success", response.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <div className="login-container">
            <Typography variant="h4" align="center" marginBottom={"32px"}>
              Forget Password
            </Typography>
            <Typography align="center" marginBottom={"32px"}>
              Please enter your email address to receive a link to reset your
              password
            </Typography>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                autoFocus
              />
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleEmailSender}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Send"
                )}
              </Button>
            </Box>{" "}
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};
export default ForgetPassword;
