import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Home = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const backendURL = process.env.REACT_APP_API_URL;
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    getAllProducts();
    getUser();
  }, []);
  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userInfo));
  }, [userInfo]);
  const getAllProducts = () => {
    axios
      .get(`${backendURL}/product/get-products`)
      .then((res) => {
        console.log(res);
        setAllProducts(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userId = localStorage.getItem("userId");
  const getUser = async () => {
    console.log("inside function");
    await axios
      .get(`${backendURL}/user/get-user/${userId}`)
      .then((res) => {
        console.log(res);
        setUserInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddToCart = (selectedProduct) => {
    const existingItem = cartItems.find(
      (item) => item._id === selectedProduct._id
    );
    console.log("updatedcart", selectedProduct?._id, cartItems.id);

    if (existingItem) {
      const updatedCart = cartItems?.map((item) =>
        item._id === selectedProduct?._id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      setCartItems(updatedCart);
    } else {
      setCartItems([...cartItems, { ...selectedProduct, quantity: 1 }]);
    }
  };

  const handleRemoveFromCart = (selectedProduct) => {
    const existingItem = cartItems.find(
      (item) => item._id === selectedProduct._id
    );

    if (existingItem && existingItem.quantity > 1) {
      const updatedCart = cartItems.map((item) =>
        item._id === selectedProduct._id
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
      setCartItems(updatedCart);
    } else {
      const updatedCart = cartItems.filter(
        (item) => item._id !== selectedProduct._id
      );
      setCartItems(updatedCart);
    }
  };

  const handleViewCart = () => {
    const totalPrice = cartItems.reduce((total, item) => {
      console.log("Item Price:", item.price, "Item Quantity:", item.quantity);
      return total + parseFloat(item.price) * item.quantity;
    }, 0);
    navigate("/checkout", { state: { cartItems, totalPrice } });
  };
  const cardStyle = {
    background: "transparent",
    backdropFilter: "blur(4px)",
    width: "260px",
    boxShadow:
      "12px 7px 5px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  };
  return (
    <Container>
      {allProducts ? (
        <Grid container spacing={3} paddingTop={"30px"} marginBottom={"30%"}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={5}>
              {allProducts?.map((product) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={product.id}
                  sx={{ marginRight: "70px" }}
                >
                  <Card sx={cardStyle}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={product.name}
                        height="200"
                        width="200"
                        image={product.imageUrl}
                      />
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {product.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Price: ${product.price}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{
                            maxWidth: "300px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {product.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddToCart(product)}
                      sx={{ margin: "5px" }}
                    >
                      +1
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveFromCart(product)}
                      sx={{ margin: "5px" }}
                    >
                      -1
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
              <Typography variant="h6" gutterBottom>
                Shopping Cart
              </Typography>
              {cartItems.length === 0 ? (
                <Typography variant="body2" color="textSecondary">
                  Your cart is empty.
                </Typography>
              ) : (
                <>
                  <List>
                    {cartItems.map((item) => (
                      <ListItem key={item.id}>
                        <Typography>
                          {item.name} x {item.quantity} ={" "}
                          {item.price * item.quantity}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleViewCart}
                    style={{ marginTop: "20px" }}
                  >
                    View Cart
                  </Button>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography variant="h3" color="textSecondary">
            No products yet
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Home;
