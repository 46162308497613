// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  top: 77px !important;
  /* border-right: none !important; */
}
.css-19r6kue-MuiContainer-root {
  max-width: none !important;
}
.css-12kj135-MuiGrid-root {
  background: linear-gradient(180deg, #1976d2 0%, #ffffff 100%);
}
.css-1p823my-MuiListItem-root {
  padding-top: 25px !important;
  padding-left: 32px !important;
}
@media screen and (max-width: 900px) {
  .sidebar-container {
    display: none;
  }
}
 `, "",{"version":3,"sources":["webpack://./src/styles/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mCAAmC;AACrC;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,6DAA6D;AAC/D;AACA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".css-12i7wg6-MuiPaper-root-MuiDrawer-paper {\n  top: 77px !important;\n  /* border-right: none !important; */\n}\n.css-19r6kue-MuiContainer-root {\n  max-width: none !important;\n}\n.css-12kj135-MuiGrid-root {\n  background: linear-gradient(180deg, #1976d2 0%, #ffffff 100%);\n}\n.css-1p823my-MuiListItem-root {\n  padding-top: 25px !important;\n  padding-left: 32px !important;\n}\n@media screen and (max-width: 900px) {\n  .sidebar-container {\n    display: none;\n  }\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
